<template>
  <section>
    <v-carousel
      cycle
      hide-delimiters
      hide-delimiter-background
      :show-arrows="false"
      :show-arrows-on-hover="false"
      :height="sliderHeight"
    >
      <v-carousel-item
        v-for="(slider, i) in sliders"
        :key="i"
        :src="slider.img"
      ></v-carousel-item>
    </v-carousel>

    <v-container text-center class="tnf-div">
      <v-layout wrap>
        <v-flex xs12 sm12 md6 lg6 style="display: flex; align-items: center;justify-content: center;background:#F2F3F5;">
          <v-flex xs12 sm12 md12 lg12 pa-4 class="text-left" style="max-width:500px;">
            <v-flex xs12 sm12 md12 lg12 pa-2>
              <v-img
                width="111"
                height="66"
                src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/change_icon.png"
                aspect-ratio="1"
                class="tnf-card-img"
              ></v-img>
            </v-flex>
            <v-flex xs12 sm12 md12 lg12 pa-2>
              <h3 style="font-size:32px;color:#0B1531;font-weight:500;line-height:48px;">快窗闪换服务</h3>
            </v-flex>
            <v-flex xs12 sm12 md12 lg12 pa-2>
              <h4 style="font-size: 20px; font-weight: 500; color: #0D65A5; line-height: 30px;"><a href="/quick_change">快窗闪换 无忧服务</a></h4>
            </v-flex>
            <v-flex xs12 sm12 md12 lg12 pa-2>
              <p style="font-size:16px; color:#0B1531;line-height:32px;">快窗闪换是针对既有住宅业主不装修换窗需求开发的意向服务。能够在不破坏现有装修的前提下快速更换门窗，不需要修补洞口。可根据客户不同的换窗需求，选择不同配置实现隔音降噪和节能保温的不同等级。能够迅速、简洁、干净的解决业主的换窗需求；不用搬家，不用敲墙，不破坏原有的装修，省去传统换窗带来的各种麻烦。</p>
            </v-flex>
          </v-flex>
        </v-flex>
        <v-flex xs12 sm12 md6 lg6 pr-2 class="text-center" style="display: flex; align-items: center;justify-content: center;background:#0D68AA;">
          <v-flex xs12 sm12 md12 lg12 class="text-left" style="max-width:602px;">
            <a href="/quick_change">
              <v-img
                max-height="590"
                max-width="602"
                src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/change_service.png"
                aspect-ratio="1"
                class="tnf-card-img"
              ></v-img>
            </a>
          </v-flex>
        </v-flex>
      </v-layout>
      <v-layout wrap>
        <v-flex xs12 sm12 md12 lg12 pa-3 mt-6 mb-3 class="tnf-home-video">
          <template>
            <v-row justify="center">
              <v-img
                src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/video.png"
                contain
                class="tnf-card-img"
                @click.stop="dialog = true"
                width="100%"
              ></v-img>
              <v-dialog
                v-model="dialog"
                max-width="960"
              >
                <v-card class="tnf-video-card">
                  <v-card-title class="tnf-video-title">
                    <v-layout wrap>
                      <v-flex xs10 sm10 md10 lg10 xl10></v-flex>
                      <v-flex xs2 sm2 md2 lg2 xl2 class="text-right">
                        <v-btn icon dark @click="dialog = false" >
                          <v-icon color="#000">mdi-close</v-icon>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-card-title>
                  <v-card-text>
                    <video :width="viedoWidth" controls style="padding:0;background:#000;object-fit:fill;" id="player" poster="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/video.png">
                      <source src="https://citiking-web.oss-cn-beijing.aliyuncs.com/video/%E8%9C%95%E5%8F%98%E6%97%B6%E5%88%BB2-CCTV%E6%A0%87-%E5%8E%8B%E7%BC%A9%E7%89%88.mp4" type="video/mp4" />
                    </video>
                  </v-card-text>
                </v-card>
              </v-dialog>

            </v-row>
          </template>
        </v-flex>
      </v-layout>
      <v-layout wrap>
        <v-flex xs12 sm12 md4 lg4 mb-12 pr-md-3 pr-lg-3 class="tnf-card-div">
          <v-card class="mx-auto tnf-card" >
            <a href="/products/67">
              <v-img
                src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/nav_product.jpg"
                aspect-ratio="1"
                class="tnf-card-img"
              ></v-img>
            </a>
            <div class="tnf-card-text">
              <h1>产品中心</h1>
            </div>
          </v-card>
        </v-flex>
        <v-flex xs12 sm12 md4 lg4 mb-12 pl-md-3 pl-lg-3 pr-md-3 pr-lg-3 class="tnf-card-div">
          <v-card class="mx-auto tnf-card" >
            <a href="/cases/15">
              <v-img
                src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/nav_case.jpg"
                aspect-ratio="1"
                class="tnf-card-img"
              ></v-img>
            </a>
            <div class="tnf-card-text">
              <h1>项目案例</h1>
            </div>
          </v-card>
        </v-flex>
        <v-flex xs12 sm12 md4 lg4 mb-12 pl-md-3 pl-lg-3 class="tnf-card-div">
          <v-card class="mx-auto tnf-card" >
            <a href="/story">
              <v-img
                src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/nav_history.jpg"
                aspect-ratio="1"
                class="tnf-card-img"
              ></v-img>
            </a>
            <div class="tnf-card-text">
              <h1>品牌故事</h1>
            </div>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout wrap class="tnf-btn-nav">
        <v-flex xs12 sm12 md6 lg3 mb-4 pr-lg-3 pr-md-2>
            <v-btn depressed large block href="/unify">洞口一体化解决方案</v-btn>
        </v-flex>
        <v-flex xs12 sm12 md6 lg3 mb-4 pl-lg-3 pr-lg-3 pl-md-2>
            <v-btn depressed large block href="/join">招商加盟</v-btn>
        </v-flex>
        <v-flex xs12 sm12 md6 lg3 mb-4 pl-lg-3 pr-lg-3 pr-md-2>
            <v-btn depressed large block href="/news">资讯动态</v-btn>
        </v-flex>
        <v-flex xs12 sm12 md6 lg3 mb-4 pl-lg-3 pl-md-2>
            <v-btn depressed large block href="/contact">联系我们</v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </section>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    showArraws: false,
    sliders: null,
    pageId: 0,
    tabProducts: null,
    tabNews: null,
    tabCases: null,
    innerWidth: window.innerWidth,
    detail: null,
    publish: null,
    sliderHeight: 710,
    viedoWidth: window.innerWidth > 960 ? 960 : '100%'
  }),
  created () {
    if (window.innerWidth < 600) {
      this.sliderHeight = 220
    } else if (window.innerWidth < 960) {
      this.sliderHeight = 360
    } else {
      this.sliderHeight = 710
    }
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.$nextTick(function () {
          this.sliders = val.banner[this.pageId]
        })
      },
      deep: true
    },
    dialog: {
      handler: function (val, oldVal) {
        if (val === false) {
          document.getElementById('player').pause()
        } else {
          this.$nextTick(function () {
            document.getElementById('player').play()
          })
        }
      },
      deep: true
    }
  },
  computed: {
    info () {
      let sliders = { '0': [] }
      let bannerProduct = {}
      let bannerCase = {}
      let bannerAbout = {}
      let bannerContact = {}
      /*
      let locale = localStorage.getItem('locale')
      if (!locale) {
        locale = 'cn'
      }
      */
      if (this.$store.getters.companyInfo.banner) {
        sliders = this.$store.getters.companyInfo.banner[this.pageId]
        bannerProduct = this.$store.getters.companyInfo.banner[2]
        bannerCase = this.$store.getters.companyInfo.banner[3]
        bannerAbout = this.$store.getters.companyInfo.banner[10]
        bannerContact = this.$store.getters.companyInfo.banner[6]
      }
      return {
        sliders: sliders,
        bannerProduct: bannerProduct,
        bannerCase: bannerCase,
        bannerAbout: bannerAbout,
        bannerContact: bannerContact
      }
    }
  },
  methods: {
  }
}
</script>
<style lang="css">
  @import '../css/home.css';
</style>
